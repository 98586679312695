import { mapGetters, mapState } from 'vuex'

export default {
	computed: {
		...mapState({
			subscription: 'subscription',
		}),
		...mapGetters({
			language: 'i18n/getLanguage',
			accountPlatform: 'location/accountPlatform',
			getQueryParam: 'location/getQueryParam',
			userBmi: 'getBmi',
			countryCode: 'getCountryCode',
			getPropertyValue: 'survey/getPropertyValue',
			commitmentPlanPeriod: 'getCommitmentPlanPeriod',
			appTheme: 'getAppTheme',
			isOrganicTrafficOnboarding: 'userFlags/getIsOrganicTrafficOnboarding',
			FEAT_CONFIRM_EMAIL_BY_CODE: 'coreFeatures/FEAT_CONFIRM_EMAIL_BY_CODE',
		}),
		IS_ACCOUNT_DELETION_DEEPLINK_EXP() {
			return this.getQueryParam('exp') === 'ios_improve_account_deletion'
		},
		IS_ACCOUNT_DELETION_DEEPLINK_V2_EXP() {
			return this.getQueryParam('exp') === 'ios_improve_account_deletion_v2'
		},
		IS_ANDROID_ACCOUNT_DELETION_DEEPLINK_EXP() {
			return this.getQueryParam('exp') === 'android_improve_account_deletion'
		},
		IS_AUTH0_ACCOUNT_DELETION_DEEPLINK_ACCOUNT_DELETION() {
			return this.getQueryParam('exp') === 'auth0_rollout_deletion'
		},
		IS_AUTH0_ACCOUNT_DELETION_DEEPLINK_EXP_MANAGE_SUBS() {
			return this.getQueryParam('exp') === 'auth0_rollout_manage_subscription'
		},
		IS_BELLY_TYPES_CREATIVES_WELCOME() {
			return this.getQueryParam('utm_term')?.toLowerCase?.().includes('bellytype')
		},
		IS_CONFIRM_EMAIL_BY_CODE_EXP() {
			return (this.accountPlatform.ios || this.accountPlatform.android) && this.FEAT_CONFIRM_EMAIL_BY_CODE
		},
		IS_NEW_ACCOUNT_PAGE_EXP() {
			return (
				this.accountPlatform.ios &&
				Boolean(!this.commitmentPlanPeriod) &&
				this.$absmartly.treatment('web_new_account_page')
			)
		},
		IS_HIDE_ZIPCODE_FIELD() {
			return this.$absmartly.treatment('web_hide_zipcode_field')
		},
		IS_APPLE_PAY_AS_DEFAULT_CTA_EXP() {
			return this.$absmartly.treatment('web_apple_pay_as_default_cta')
		},
		IS_FEMALE_ACTIVITY_NEW_UPGRADES_EXP() {
			if (
				this.countryCode === 'us' &&
				(this.AREA.BAU_FEMALE || this.AREA.PILATES_FEMALE) &&
				this.IS_NEW_VALUE_FOR_UPGRADES_COMMITMENT_EXP
			) {
				return true
			}

			if (this.IS_MALE_NEW_VALUE_FOR_UPGRADES_EXP) {
				return true
			}

			return [
				this.AREA.ACTIVITY,
				this.AREA.YOGA,
				this.AREA.PILATES_FEMALE && this.countryCode !== 'us',
				this.AREA.WALKING,
				this.AREA.SOMATIC_FEMALE,
			].some(Boolean)
		},
		IS_MALE_NEW_VALUE_FOR_UPGRADES_EXP() {
			return this.$absmartly.treatment('web_male_new_value_for_upgrades')
		},
		IS_NEW_VALUE_FOR_UPGRADES_COMMITMENT_EXP() {
			return (
				this.$absmartly.treatment('web_female_new_value_for_upgrades') ||
				this.$absmartly.treatment('web_pilates_new_value_for_upgrades')
			)
		},
		IS_NEW_AGE_SCREEN_EXP() {
			return this.$absmartly.treatment('web_new_age_screen')
		},
		IS_NEW_CANCELLATION_OFFER_AND_UI_EXP() {
			return !Boolean(this.commitmentPlanPeriod) && this.$absmartly.treatment('web_new_cancellation_offer_and_ui')
		},
		IS_COMMITMENT_DELAY_VS_FEES_EXP() {
			return Boolean(this.commitmentPlanPeriod) && this.$absmartly.treatment('web_commitment_delay_vs_fees') === 1
		},
		IS_COMMITMENT_CANCELLATION_FEES_I2_EXP() {
			return Boolean(this.commitmentPlanPeriod) && this.$absmartly.treatment('web_commitment_delay_vs_fees') === 2
		},
		IS_MALE_FUNNEL_HOTJAR_EXP() {
			return this.$absmartly.treatment('web_male_funnel_hotjar_track')
		},
		IS_FEMALE_FUNNEL_HOTJAR_EXP() {
			return this.$absmartly.treatment('web_female_funnel_hotjar_track')
		},
		IS_CANCELLATION_FLOW_CA_EXP() {
			return (
				this.getQueryParam('utm_source') === 'simple_landing' &&
				this.getQueryParam('utm_medium') === 'manage_subscription_ca'
			)
		},
		IS_NEW_CANCELLATION_UI() {
			return Boolean(
				this.IS_CANCELLATION_FLOW_CA_EXP || this.IS_NEW_CANCELLATION_OFFER_AND_UI_EXP || this.commitmentPlanPeriod,
			)
		},
		IS_ACTIVITY_FACE_MASSAGE_UPSELL_EXP() {
			return this.$absmartly.treatment('web_activity_face_massage_upsell_exp')
		},
		IS_YOGA_FACE_MASSAGE_UPSELL_EXP() {
			return this.$absmartly.treatment('web_yoga_face_massage_upsell_exp')
		},
		IS_PILATES_FACE_MASSAGE_UPSELL_EXP() {
			return this.$absmartly.treatment('web_pilates_face_massage_upsell_exp')
		},
		IS_MALE_WALKING_PAYWALL_OPTIMIZATION_EXP() {
			return this.$absmartly.treatment('web_male_walking_paywall_optimization')
		},
		IS_FEMALE_CHAIR_YOGA_EXERCISES_EXAMPLES_EXP() {
			return this.$absmartly.treatment('web_female_yoga_exercises_examples')
		},
		IS_SEX_HEALTH_UPSELL_EXP() {
			return this.$absmartly.treatment('web_sex_health_upsell')
		},
		IS_FEMALE_CHAIR_YOGA_PAYWALL_UPDATES_EXP() {
			return this.$absmartly.treatment('web_female_yoga_paywall_updates')
		},
		IS_AGE_SELECTION_LANDING_TYPE() {
			return this.getQueryParam('lptype') === 'age_selection'
		},
		IS_YOU_PLAN_READY_ANIMATION_EXP() {
			return (
				this.$absmartly.treatment('web_you_plan_ready_animation') ||
				this.$absmartly.treatment('web_you_plan_ready_animation_rollout')
			)
		},
		IS_FEMALE_ACTIVITY_SOMATIC_YOGA_COMP_FUNNEL_EXP() {
			return this.$absmartly.treatment('web_female_somatic_yoga_comp')
		},
		IS_FEMALE_ACTIVITY_CHAIR_YOGA_COMP_FUNNEL_EXP() {
			return this.$absmartly.treatment('web_female_chair_yoga_comp')
		},
		IS_UPDATE_COOKIE_WIDGET_DESIGN_EXP() {
			return (
				this.$absmartly.treatment('web_update_cookie_widget_design') ||
				this.$absmartly.treatment('web_update_cookie_widget_design_rollout')
			)
		},
		IS_SELLING_POINTS_BEFORE_PAYWALL_EXP() {
			return this.$absmartly.treatment('web_selling_points_before_paywall')
		},
		IS_CANCEL_OFFER_ONE_DOLLAR_FOREVER_EXP() {
			if (this.subscription?.provider !== 'palta') {
				return
			}
			return this.$absmartly.treatment('web_one_dollar_offer_forever')
		},
		IS_COMMITMENT_CANCEL_OFFER_ONE_DOLLAR_FOREVER_EXP() {
			if (this.subscription?.provider !== 'palta') {
				return
			}
			return this.$absmartly.treatment('web_commitment_one_dollar_offer_forever')
		},
		IS_WL_PREDICTION_UPDATE_EXP() {
			return this.$absmartly.treatment('web_wl_prediction_update')
		},
		IS_WELCOME_COOKIE_OPTIMIZATION_EXP() {
			return this.$absmartly.treatment('web_welcome_cookie_optimization')
		},
		IS_SHOW_AUTORENEWAL_CONSENT_ON_PAYWALL_EXP() {
			return this.$absmartly.treatment('web_show_autorenewal_consent_on_paywall')
		},
		IS_STEP_BY_STEP_COURSES_ADD_EXP() {
			return this.$absmartly.treatment('web_step_by_step_courses_add')
		},
		IS_PERSONALIZED_DISPLAY_OF_PLANS_EXP() {
			return this.$absmartly.treatment('web_personalized_display_of_plans')
		},
		IS_PREDICT_AND_PRICES_SEPARATE_SCREENS_EXP() {
			return this.$absmartly.treatment('web_predict_and_prices_separate')
		},
		IS_FALLBACK_FOR_PAYMENT_FAILURE_EXP() {
			return this.$absmartly.treatment('web_fallback_for_payment_failure')
		},
		IS_NEW_PRICES_FASTING_UPSELLS_EXP_B() {
			return this.$absmartly.treatment('web_new_prices_fasting_upsells') === 1
		},
		IS_NEW_PRICES_FASTING_UPSELLS_EXP_C() {
			return this.$absmartly.treatment('web_new_prices_fasting_upsells') === 2
		},
		IS_APPLE_PAY_BUTTON_RENAME_EXP() {
			return this.$absmartly.treatment('web_apple_pay_button_rename')
		},
		IS_ONE_PREDICT_INSTEAD_OF_TWO_EXP() {
			return this.$absmartly.treatment('web_one_predict_instead_of_two')
		},
		IS_SPREADSHEET_CREATIVES_EXP() {
			return this.IS_SPREADSHEET_CREATIVES_EXP_B || this.IS_SPREADSHEET_CREATIVES_EXP_C
		},
		IS_SPREADSHEET_CREATIVES_EXP_B() {
			const utmTerm = this.getQueryParam('utm_term')?.toLowerCase?.()
			const isValidUtmTerm = utmTerm?.includes('excel')

			return isValidUtmTerm && this.$absmartly.treatment('web_spreadsheet_creatives') === 1
		},
		IS_SPREADSHEET_CREATIVES_EXP_C() {
			const utmTerm = this.getQueryParam('utm_term')?.toLowerCase?.()
			const isValidUtmTerm = utmTerm?.includes('excel')

			return isValidUtmTerm && this.$absmartly.treatment('web_spreadsheet_creatives') === 2
		},
		IS_WEB_PAYWALL_YOGA_FEMALE_COMP_EXP() {
			return this.$absmartly.treatment('web_paywall_yoga_female_comp')
		},
		IS_WEB_PAYWALL_SOMATIC_FEMALE_COMP_EXP() {
			return this.$absmartly.treatment('web_paywall_somatic_female_comp')
		},
		IS_GET_FREE_MONTHS_FEMALE_EXP() {
			return this.$absmartly.treatment('web_get_free_months_female')
		},
	},
}
